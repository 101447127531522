import React, { useState, useEffect, useContext, useRef } from "react";
import { LuSettings2 } from "react-icons/lu";
import { FiSearch } from "react-icons/fi";
import { LuFiles } from "react-icons/lu";
import { BiTimeFive } from "react-icons/bi";
import { LuFileText } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { getDocumentPage } from "../../api/GetActions";
import { AnimationDocument } from "../../utils/images/index.js";
import { PreloaderDocument } from "../../utils/images/index.js";
import { MyContext } from "../../context/ContextApi";
import { documentImage } from "../../utils/images/index.js";
import "./documentGeneration.css";

const DocumentGeneration = () => {
  const menuRef = useRef(null);
  const { isSideBarOpen, setRawHtmlContent, setContentID, setContentHeading } =
    useContext(MyContext);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [activeMenu, setActiveMenu] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [articles, setArticles] = useState([]);
  const [headings, setHeadings] = useState({});
  const [editableTitles, setEditableTitles] = useState({});
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const disabledItems = [
    "IPD Doc",
    "IND Doc",
    "Analysis",
    "Document",
    "Slides",
  ];


  useEffect(() => {
    const fetchArticles = async () => {
      const userId = sessionStorage.getItem("userID");
      if (!userId) {
        console.error("User ID not found in sessionStorage");
        return;
      }

      try {
        setLoading(true);
        const response = await getDocumentPage(userId);

        if (response && response.contents) {
          const groupedByType = response.contents.reduce((acc, item) => {
            const { type } = item;
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(item);
            return acc;
          }, {});

          setHeadings(groupedByType);

          const formattedArticles = Object.keys(groupedByType).flatMap((type) =>
            groupedByType[type].map((article) => ({
              ...article,
              heading: type,
            }))
          );

          setArticles(formattedArticles);
          setLoading(false);
        } else {
          console.error(
            "Invalid API response: Response is empty or missing contents."
          );
        }
      } catch (error) {
        console.error("Error fetching Contents:", error);
      }
    };

    fetchArticles();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setActiveMenu(null);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  const filteredArticles = articles.filter((article) => {
    const matchesSearch =
      searchText === "" ||
      article.title.toLowerCase().includes(searchText.toLowerCase()) ||
      article.heading.toLowerCase().includes(searchText.toLowerCase());

    const formattedSelectedDate = selectedDate ? formatDate(selectedDate) : "";
    const formattedArticleDate = article.updated_at
      ? formatDate(article.updated_at)
      : "";

    const matchesDate =
      formattedSelectedDate === "" ||
      formattedArticleDate === formattedSelectedDate;

    return matchesSearch && matchesDate;
  });

  const handleViewNow = (article) => {
    setSelectedArticle(article);
  };

  const closeModal = () => {
    setSelectedArticle(null);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleMenuClick = (heading, index, event) => {
    event.stopPropagation();
    setActiveMenu((prevMenu) =>
      prevMenu && prevMenu.heading === heading && prevMenu.index === index
        ? null
        : { heading, index }
    );
  };

  const handleOptionClick = (action, item, index, headingKey) => {
    if (action === "Download") {
      const blob = new Blob([item.article_content], { type: "text/html" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${item.title || "document"}.html`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }

    if (action === "Rename") {
      setEditableTitles({
        ...editableTitles,
        [`${headingKey}-${index}`]: item.title,
      });
    }

    if (action === "Share") {
      const teamsMessage = `**${item.title}**\n\n**Date:** ${item.date}\n\n**Content:**\n${item.content}`;
      const encodedMessage = encodeURIComponent(teamsMessage);
      const teamsDeepLink = `https://teams.microsoft.com/l/chat/0/0?users=<user_email>&message=${encodedMessage}`;
      window.open(teamsDeepLink, "_blank");
    }

    if (action === "Edit") {
      setRawHtmlContent(item.article_content);
      setContentID(item.content_id);
      setContentHeading(headingKey);
      navigate("/documentEditor");
    }

    setActiveMenu(null);
  };

  const handleContentSelect = (content) => {
    setSelectedContent(content);
  };

  const handleProceed = () => {
    if (selectedContent) {
      navigate(`/documentStructure?content=${selectedContent}`);
    } else {
      alert("Please select a content type!");
    }
  };

  return (
    <div className="content-page">
      {loading && (
        <div className="loading-heading-container">
          <div className="loading-heading-box">
            <img
              src={AnimationDocument}
              alt="Loading animation"
              className="loading-heading-animation"
            />
            <div className="loading-heading-div">
              <span className="loading-heading-text">
                Processing, Please wait
              </span>
              <img
                src={PreloaderDocument}
                alt="Loading dots"
                className="loading-heading-dots"
              />
            </div>
          </div>
        </div>
      )}
      <h4 className={`${loading ? "dim-content" : ""}`}>Content Generation</h4>
      <div className={`header ${loading ? "dim-content" : ""}`}>
        <div className="search-container">
          <FiSearch className="search-icon" />
          <input
            type="text"
            placeholder="Article, Journal Paper and more ....."
            className="search-bar"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </div>
        <div className="header-buttons">
          <button
            className="filter-btn"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <LuSettings2 style={{ marginRight: "10px" }} />
            Filters
          </button>
          <button className="createnewcontent-btn" onClick={handleOpenModal}>
            + Create New Content
          </button>
        </div>
      </div>

      {isFilterOpen && (
        <div className={`filter-tooltip ${loading ? "dim-content" : ""}`}>
          <label htmlFor="filter-date" className="filter-label">
            Filter by Date:
          </label>
          <input
            type="date"
            id="filter-date"
            className="date-filter"
            onChange={(e) => setSelectedDate(e.target.value)}
          />
        </div>
      )}

      <div className={`section ${loading ? "dim-content" : ""}`}>
        {Object.keys(headings).length > 0 ? (
          Object.keys(headings).map((headingKey, index) => {
            const filteredByHeading = filteredArticles.filter(
              (article) => article.heading === headingKey
            );
            if (filteredByHeading.length === 0) {
              return null;
            }

            return (
              <div key={index}>
                <h5 className="section-title">{headingKey}</h5>
                <div
                  className={`card-wrapper ${
                    isSideBarOpen ? "card-wrapperMin" : "card-wrapperMax"
                  }`}
                >
                  <div className="card-container">
                    {filteredByHeading.map((item, cardIndex) => (
                      <div
                        className={`card ${
                          isSideBarOpen ? "cardMin" : "cardMax"
                        }`}
                        key={cardIndex}
                      >
                        <img src={documentImage} className="card-image" />
                        <div className="card-content">
                          <h5 className="card-title">
                            {editableTitles[`${headingKey}-${cardIndex}`] ? (
                              <input
                                type="text"
                                value={
                                  editableTitles[`${headingKey}-${cardIndex}`]
                                }
                                onChange={(e) =>
                                  setEditableTitles({
                                    ...editableTitles,
                                    [`${headingKey}-${cardIndex}`]:
                                      e.target.value,
                                  })
                                }
                                onBlur={() => {
                                  const updatedArticles = articles.map(
                                    (article) => {
                                      if (
                                        article.heading === headingKey &&
                                        filteredByHeading.indexOf(article) ===
                                          cardIndex
                                      ) {
                                        return {
                                          ...article,
                                          title:
                                            editableTitles[
                                              `${headingKey}-${cardIndex}`
                                            ],
                                        };
                                      }
                                      return article;
                                    }
                                  );
                                  setArticles(updatedArticles);

                                  setEditableTitles({
                                    ...editableTitles,
                                    [`${headingKey}-${cardIndex}`]: null,
                                  });
                                }}
                                autoFocus
                              />
                            ) : (
                              <span className="title-container">
                                <LuFiles
                                  style={{ marginRight: "5px" }}
                                  size={20}
                                  color="#035eb7"
                                />
                                <span className="title-text">
                                  {item.title}
                                  <span className="titletooltip">
                                    {item.title}
                                  </span>
                                </span>
                              </span>
                            )}
                          </h5>
                          <span className="card-date">
                            <BiTimeFive />
                            {item.updated_at}
                          </span>
                          <button
                            className="view-now"
                            onClick={() => handleViewNow(item.article_content)}
                          >
                            View Now
                          </button>
                        </div>
                        <div className="menu-container" ref={menuRef}>
                          <div
                            className="menu-icon"
                            onClick={(event) =>
                              handleMenuClick(headingKey, cardIndex, event)
                            }
                          >
                            &#8942;
                          </div>
                          {activeMenu &&
                            activeMenu.heading === headingKey &&
                            activeMenu.index === cardIndex && (
                              <div className="tooltip-menu">
                                <div
                                  className="tooltip-option"
                                  onClick={() =>
                                    handleOptionClick(
                                      "Edit",
                                      item,
                                      cardIndex,
                                      headingKey
                                    )
                                  }
                                >
                                  Edit
                                </div>
                                <div
                                  className="tooltip-option"
                                  onClick={() =>
                                    handleOptionClick(
                                      "Share",
                                      item,
                                      cardIndex,
                                      headingKey
                                    )
                                  }
                                >
                                  Share
                                </div>
                                <div
                                  className="tooltip-option"
                                  onClick={() =>
                                    handleOptionClick(
                                      "Rename",
                                      item,
                                      cardIndex,
                                      headingKey
                                    )
                                  }
                                >
                                  Rename
                                </div>
                                <div
                                  className="tooltip-option"
                                  onClick={() =>
                                    handleOptionClick(
                                      "Download",
                                      item,
                                      cardIndex,
                                      headingKey
                                    )
                                  }
                                >
                                  Download
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>No Contents found.</p>
        )}
      </div>

      {selectedArticle && (
        <div className={`modaldoc ${loading ? "dim-content" : ""}`}>
          <div className="modaldoc-content">
            <button className="close-doc-button" onClick={closeModal}>
              &times;
            </button>
            <div dangerouslySetInnerHTML={{ __html: selectedArticle }}></div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className={`modal-overlay ${loading ? "dim-content" : ""}`}>
          <div className="modal-box">
            <div className="modal-header">
              <h2>Create New Content</h2>
              <button className="close-btn" onClick={handleCloseModal}>
                &times;
              </button>
            </div>
            <div className="modal-content-generation">
              <div className="content-grid">
                {[
                  "Article",
                  "IPD Doc",
                  "IND Doc",
                  "Analysis",
                  "Document",
                  "Dynamic Banner",
                  "Journal Paper",
                  "Email",
                  "Slides",
                ].map((item, index) => (
                  <div
                    key={index}
                    className={`content-card ${
                      selectedContent === item ? "active" : ""
                    } ${disabledItems.includes(item) ? "disabled" : ""}`}
                    onClick={
                      !disabledItems.includes(item)
                        ? () => handleContentSelect(item)
                        : null
                    }
                  >
                    <div className="content-icon">
                      <LuFileText />
                    </div>
                    <span>{item}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-footer">
              <button className="cancel-btn" onClick={handleCloseModal}>
                Cancel
              </button>
              <button className="proceed-btn" onClick={handleProceed}>
                Proceed
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentGeneration;
