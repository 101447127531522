const dev = process.env.REACT_APP_API_DEMO_URL; // Test
const hcp_Mlt_Demo = process.env.REACT_APP_API_DEMO_HCP_MLT_URL;
const persona = process.env.REACT_APP_API_DEMO_PERSONA;
const docSonar = process.env.REACT_APP_API_DEMO_DOCSONAR_BUCKETLIST;
const pubmed = process.env.REACT_APP_API_DEMO_PUBMEDQUERY;
const content_tagging = process.env.REACT_APP_API_DEMO_CONTENT_TAGGING;
const medFusion = process.env.REACT_APP_API_DEV_URL_MED_FUSION;
const com_data_detect = process.env.REACT_APP_API_DEMO_URL_COM_DATA_DETECT;
const dash_lens = process.env.REACT_APP_API_DEMO_URL_DASH_LENS;
const data_lens = process.env.REACT_APP_API_DEMO_URL_DATA_LENS;
const GeneInspector = process.env.REACT_APP_API_DEMO_URL_GENE_INSPECTOR;
const omni_chatbot = process.env.REACT_APP_API_DEV_URL_OMNI_CHATBOT;
const document_generation =
  process.env.REACT_APP_API_DEV_URL_DOCUMENT_GENERATION; 

export const postUrl = {
  sendToken: `${dev}/sso/authentication`,
  createPersona: `${persona}/data_lens/add_persona`,
  createUser: `${dev}/login/create_user/`,
  login: `${dev}/login/user_login/`,
  prompt: `${data_lens}/data_lens/text_result`, // using old port 444 for data lens
  sessionlist: `${persona}/data_lens/get_sessions/`,
  sessionHistory: `${dev}/session/list_session_chat/`,
  sessionHistoryDataLens: `${data_lens}/data_lens/list_session_chat/`,
  audio: `${dev}/Email/create_audio/`,
  sendEmail: `${dev}/Email/send_email/`,
  shareWhatsApp: `${dev}/WhatsApp/send_message_text/`,
  kpi: `${dev}/kpi/summary/`,
  // uploadDocuments: `${dev}/document_search/upload_document/`,
  uploadDocuments: `https://geninsights.agilisiumtech.com:448/document_search/upload_document`,
  // uploadDocuments: `https://dev-geninsights.agilisiumtech.com:448/document_search/upload_document`,

  documentNewsession: `${docSonar}/document_session/new_doc_search_session/`,
  documentChathistory: `${docSonar}/document_session/Display_doc_search_session_chat/`,
  // documentPrompt: `${dev}/document_search/retrieve_from_document/`,
  documentPrompt: `https://geninsights.agilisiumtech.com:448/document_search/retrieve_from_document`,

  createCollection: `https://geninsights.agilisiumtech.com:462/login/create_collection/`,
  localDocument: `https://geninsights.agilisiumtech.com:448/document_search/local_doc_upload`,
  bucketList: `${docSonar}/document_search/bucket_list`,
  dashboardPrompt: `${dash_lens}/dashboard_search/dashboard_chat/`,
  summaryDashboard: `${dash_lens}/dashboard_search/dashboard_summary/`,
  dashboardSessionHistory: `${dash_lens}/dasboard_session/get_dashboard_search_sessions/`,
  dashboardChathistory: `${dash_lens}/dasboard_session/Display_dashboard_session_chat/`,
  schedule: `${dev}/scheduler/create-rule/`,
  feedback: "https://geninsights.agilisiumtech.com:444/session/feedback-data/",
  data_audio_history: `${data_lens}/data_lens/data_audio_history/`,
  document_audio_history: `${docSonar}/document_session/document_audio_history/`,
  dashboard_audio_history: `${dash_lens}/dasboard_session/dashboard_audio_history/`,
  pubmedSearch: `${pubmed}/pubmed/pubmed_search/`,
  referencePapers: `${pubmed}/pubmed/reference_papers/`,
  dataRenamesession: `${data_lens}/data_lens/rename_data_lens_sessions/`,
  documentRenamesession: `${dash_lens}/document_session/rename_doc_search_sessions/`,
  dashboardRenamesession: `${dash_lens}/dasboard_session/rename_dashboard_search_sessions/`,
  dashRenameSession: `${dash_lens}/text_to_dashboard/rename_dash_vista_chat_ses/`,
  decodedContent: `https://geninsights.agilisiumtech.com:448/document_search/convert_path_to_encoded_content`,
  modalSwapLogs: `${dev}/login/model_swap_logs/`,
  upload_doc_pubmed: `${pubmed}/pubmed/upload_doc_pubmed/`,
  upload_local_document_pub: `${pubmed}/pubmed/upload_local_doc_pubmed/`,
  updating_disease_tagged: `${pubmed}/pubmed/updating_disease_tagged/`,
  textToDashboard: `${dash_lens}/text_to_dashboard/txt_to_dash`,
  chartChatHistory: `${dash_lens}/text_to_dashboard/display_dash_vista_history/`,
  chartSessionHistory: `${dash_lens}/text_to_dashboard/get_dash_vista_sessions/`,
  pubmedSearchSummary: `${pubmed}/pubmed/pubmed_search_summary/`,
  audioPromptForData: `https://geninsights.agilisiumtech.com:444/session/Prompt_chat_speech/`,
  audioPromptForDoc: `${docSonar}/document_search/retrieve_from_document_speech/`,
  audioPromptForDash: `${dash_lens}/dashboard_search/dashboard_chat_speech/`,
  new_chat_session: `${pubmed}/pubmed/new_chat_session/`,
  uri_endpoint: `https://geninsights.agilisiumtech.com:444/session/uri_endpoint/`,
  search_get_data_json: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/get_data_json/`,
  tableau_dashboard_summary: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/dashboard_summary/`,
  tableau_dashboard_chat: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/dashboard_chat/`,
  tableau_dashboard_chat_speech: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/dashboard_chat_speech/`,
  tableauRenameSession: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/rename_dashboard_search_sessions/`,
  tableauSessionChat: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/Display_dashboard_session_chat/`,
  tableauAudioHistory: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/dashboard_audio_history/`,
  createWorkBook: `${dev}/login/create_workbook_kpi/`,
  fetchGeneData: `${GeneInspector}/gene_inspector/fetch_gene_data/`,
  uploadDocGen: `${GeneInspector}/gene_inspector/upload_local_doc_gene/`,
  addChartResponseInHistory: `${dash_lens}/text_to_dashboard/adding_dash_vista_history/`,
  uploadDocumetGt: `${content_tagging}/content_tagging/upload_document_ct/`,
  uploadLocalDocumetGt: `${content_tagging}/content_tagging/upload_local_document_ct/`,
  contentTaggingSearch: `${content_tagging}/content_tagging/content_tagging_search/`,
  contentDbList: `${content_tagging}/content_tagging/list_databases/`,
  contentTableList: `${content_tagging}/content_tagging/list_tables/`,
  contentTagTable: `${content_tagging}/content_tagging/insert_tags_into_table/`,
  // contentTemplateUpload: `${dev}/content_generator/upload-template/`,
  contentTemplateUpload: `https://geninsights.agilisiumtech.com:555/content_generator/upload-template/`,
  // contentGenerateInfo: `${dev}/content_generator/extract-info/`,
  contentGenerateInfo: `https://geninsights.agilisiumtech.com:555/content_generator/upload-info-doc/`,
  contentFileLists: `https://geninsights.agilisiumtech.com:555/content_generator/list-reference-file/`,
  contentGenerateReference: `https://geninsights.agilisiumtech.com:555/content_generator/extract-info-reference/`,
  contentRagaScore: `https://geninsights.agilisiumtech.com:555/content_generator/ragas-score/`,
  // contentGenerateDocument: `${dev}/content_generator/generate-document/`,
  contentGenerateDocument: `https://geninsights.agilisiumtech.com:555/content_generator/generate-document/`,
  dropCollection: `https://geninsights.agilisiumtech.com:555/content_generator/drop-collection/`,
  translateDocumentUpload: `${hcp_Mlt_Demo}/lang_trans_analytics/upload/`,
  translateColumnData: `${hcp_Mlt_Demo}/lang_trans_analytics/Med_Lingo/`,
  uniqueValueForColumnData: `${hcp_Mlt_Demo}/lang_trans_analytics/MedInsights/column_values/unique_values/`,
  dataResForMedInsight: `${hcp_Mlt_Demo}/lang_trans_analytics/Med_Insights/`,
  getColumnDataForMedPulse: `${hcp_Mlt_Demo}/lang_trans_analytics/Med_Pulse/`,
  uploadMlrDoc: `https://geninsights.agilisiumtech.com:446/pii_checker/upload_pdf`,
  personalIdentityData: `https://geninsights.agilisiumtech.com:446/pii_checker/process_pii_and_watermarks`,
  claimsTagging: `https://geninsights.agilisiumtech.com:446/pii_checker/claims_tagging`,
  uploadLogo: `https://geninsights.agilisiumtech.com:446/pii_checker/upload_logo_image`,
  // waterMarkDetectionData: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/detect-watermarks/`,
  // personalIdentityWaterMark: `https://dev-geninsights.agilisiumtech.com:446/pii_checker/detect-pii-in-images/`,
  deleteCollectionListFile: `https://geninsights.agilisiumtech.com:555/content_generator/delete-ref-file-s3/`,
  deleteCollectionAllListFile: `https://geninsights.agilisiumtech.com:555/content_generator/delete-all-file-s3/`,

  dataDetectivePrompt: `https://geninsights.agilisiumtech.com:449/gen_data/detective_chat`,
  datadecRenamesession: `https://geninsights.agilisiumtech.com:449/session/rename_sessions/`,
  dataDetectiveSession: `https://geninsights.agilisiumtech.com:449/session/get_sessions/`,
  dataDetectiveChatHistory: `https://geninsights.agilisiumtech.com:449/session/list_session_chat/`,

  // commercial data detective
  comDetectivePrompt: `${com_data_detect}/gen_data/detective_chat`,
  comDetectiveSession: `${com_data_detect}/session/get_sessions/`,
  comDetetectiveRename: `${com_data_detect}/session/rename_sessions/`,
  comDetectiveChatHistory: `${com_data_detect}/session/list_session_chat/`,
  comDetectiveUpload: `${com_data_detect}/session/upload_file/`,

  getEvaluate: `https://geninsights.agilisiumtech.com:448/document_search/get_evaluate_response`,
  getEvaluateTableau: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/get-evaluate_response/`,

  getEvaluateDataLens: `https://geninsights.agilisiumtech.com:452/data_lens/evaluate-response/`,

  //zoom api
  zoomRetreivePrompt: `https://geninsights.agilisiumtech.com:450/document_search/retrieve_from_transcribed_document`,
  zoomSessionHistory: `https://geninsights.agilisiumtech.com:450/document_session/get_doc_search_sessions`,
  zoomRenamesession: `https://geninsights.agilisiumtech.com:450/document_session/rename_doc_search_sessions`,
  zoomAudioHistroy: `https://geninsights.agilisiumtech.com:450/document_session/document_audio_history`,
  zoomAudioConvert: `https://geninsights.agilisiumtech.com:450}/Email/create_audio`,

  zoomChathistory: `https://geninsights.agilisiumtech.com:450/document_session/display_doc_search_session_chat`,
  zoomDecodeContent: `https://geninsights.agilisiumtech.com:450/document_search/convert_path_to_encoded_content`,

  zoomVideoConvert: `https://geninsights.agilisiumtech.com:450/document_search/upload_local_video`,

  documentSessionHistory: `https://geninsights.agilisiumtech.com:448/document_session/get_doc_search_sessions`,
  tableauGetSessions: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/get_dashboard_search_sessions/`,

  getAnswerSalesRepCopilot: `https://geninsights.agilisiumtech.com:461/document_search/new_project`,
  getDatalensDropdownList: `${persona}/data_lens/get_dl_persona/`,

  //Med Fusion
  uploadMedFusion: `${medFusion}/audio_mapper/upload_local_audio`,
  extractedFiles: `${medFusion}/audio_mapper/extract-data/`,
  downloadData: `${medFusion}/audio_mapper/api/download-csv/`,
  queryMedFusion: `${medFusion}/audio_mapper/valid-query/`,

  //contentGeneration

  documentStructureArticlefields: `${document_generation}/content_generation/article`,
  documentStructureDynamicBannerfields: `${document_generation}/content_generation/dynamic-banner`,
  documentPlagiarismChecker: `${document_generation}/content_generation/plagiarism_checker`,
  documentStructureArticleFileUpload: `${document_generation}/content_generation/upload_multiple_documents`,
  documentStructureDynamicBannerFileUpload: `${document_generation}/content_generation/dynamic_banner_upload_multiple_documents`,
  documentStructureArticleheadings: `${document_generation}/content_generation/article/generate`,
  documentArticleAIGenerated: `${document_generation}/content_generation/rephrase_article`,
  documentDynamicBannerAIGenerated: `${document_generation}/content_generation/rephrase_dynamic_banner `,
  documentRepTriggerEmailAIGenerated: `${document_generation}/content_generation/rephrase_rep_triggered_email `,
  documentJournalPaperAIGenerated: `${document_generation}/content_generation/rephrase_journal_paper`,
  generateImage: `${document_generation}/content_generation/generate-image/`,
  documentStructureRepTriggerEmailfields: `${document_generation}/content_generation/rep_triggered_email`,
  documentStructureRepTriggerEmailFileUpload: `${document_generation}/content_generation/rep_trigger_email_upload_multiple_documents`,
  documentStructureFileUpload: `${document_generation}/content_generation/documents`,
  documentFileExtract: `${document_generation}/content_generation/generated_documents`,
  documentAIRegenerated: `${document_generation}/content_generation/regenerate_llm_response`,
  documentContentDownload: `${document_generation}/content_generation/article_download/`,

  //omnilens
  omnilensGeneratePrompt: `${omni_chatbot}/query_endpoint/main_query/`,
  omnilensSource: `${omni_chatbot}/query_endpoint/convert_path_to_encoded_content`,
  omnilensEditSession: `${omni_chatbot}/sessions_endpoint/rename_doc_search_sessions`,
  omnilensDocSearchSessions: `${omni_chatbot}/sessions_endpoint/get_doc_search_sessions`,
  omnilensDisplayDocSearchSessionChat: `${omni_chatbot}/sessions_endpoint/display_doc_search_session_chat`,
  omnilensDisplayDocSearchSessionChat: `${omni_chatbot}/sessions_endpoint/display_doc_search_session_chat`,

  documentCollectionLastUsed: `${docSonar}/document_session/collection_last_used`,
};

export const getUrl = {
  tables: `${dev}/login/list_tables/`,
  persona: `${dev}/login/list_persona/ `,
  users: `${dev}/login/list_user/`,
  tableAccess: `${dev}/login/get-persona-data/`,
  initialSetup: `${dev}/document_search/initial_setup/`, // don't know
  listCollection: `${dev}/login/list_collection/`,
  dashboardRegions: `${dash_lens}/dashboard_search/list_regions/`,
  listDashboards: `${dash_lens}/dashboard_search/list_dashboards/`,
  personauser: `${dev}/login/get_persona_tagged_users/`,
  getPersonaList: `${dev}/login/get-persona-list/`,
  getModelType: `${dev}/login/get_model_type/`,
  get_disease_tagged: `${pubmed}/pubmed/get_disease_tagged/`,
  get_all_disease_tagged: `${pubmed}/pubmed/get_all_disease_tagged`,
  tableau_list_project: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/list_project/`,
  tableau_list_workbook: `https://dev-geninsights.agilisiumtech.com:451/tableau_dashboard_search/list_workbook/`,
  getWorkBookKpi: `${dev}/login/list_workbookkpi/`,
  getWorkBookTable: `${dev}/login/list_workbookkpi_table/`,
  geneInsGeData: `${GeneInspector}/gene_inspector/get_data/`,
  downloadDocument: `https://geninsights.agilisiumtech.com:555/content_generator/download_docx/`,
  dataLens: `https://geninsights.agilisiumtech.com:444/data_lens_faq/datalens_get_faqs/`, // using old port 444 for data lens
  docSonarFaq: `https://geninsights.agilisiumtech.com:448/login/list_faqs/`,
  commericalFaq: `${com_data_detect}/login/list_faqs/`,
  pubmedFaq: `https://geninsights.agilisiumtech.com:444/pubmed_faq/pubmed_get_faqs/`, // using old port 444 for pubmed
  dataVistaFaq: `https://geninsights.agilisiumtech.com:444/dash_vista_faq/dashvista_get_faqs/`, // using old port 444 for dash vista
  getDefaultSentiments: `${hcp_Mlt_Demo}/lang_trans_analytics/default_sentiments/`,

  getDocumentList: `${document_generation}/content_generation/view`, //ContentGeneration
  downloadsession: `${data_lens}/data_lens/download_chat_session/`,
  //Med Fusion
  getSource: `${medFusion}/audio_mapper/transcription/`,
};

export const putUrl = {
  putPersona: `${persona}/data_lens/edit_persona/`,
  putUser: `${dev}/login/edit_user/`,
  editCollection: `${dev}/login/edit_collection/`,
  editWorkBook: `${dev}/login/edit_workbook_kpi/`,
  editPersona: `https://geninsights.agilisiumtech.com:452/data_lens/edit_persona/`,
};

export const deleteUrl = {
  deletePersona: `${dev}/login/delete_persona/`,
  deleteUser: `${dev}/login/delete_user/`,
  deleteSession: `https://geninsights.agilisiumtech.com:452/data_lens/clear_sessions/`,
  deleteAllSession: `https://geninsights.agilisiumtech.com:452/data_lens/delete_entire_session/`,
  deletedocument: `${docSonar}/document_session/clear_doc_search_sessions/`,
  deleteAlldocument: `${docSonar}/document_session/Delete_entire_doc_search_session/`,
  deleteCollection: `${dev}/login/delete_collection`,
  deletedashboardsession: `${dash_lens}/dasboard_session/clear_dashboard_search_sessions/`,
  deleteAlldashboardsession: `${dash_lens}/dasboard_session/Delete_entire_dashboard_search_session/`,
  delete_file_s3_pub: `${pubmed}/pubmed/delete_file_s3_pub/`,
  delete_disease_tagged_db: `${pubmed}/pubmed/delete_disease_tagged_db/`,
  deleteTableauSession: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/clear_dashboard_search_sessions/`,
  deleteTableauSessionAll: `https://dev-geninsights.agilisiumtech.com:451/tableau_dasboard_session/Delete_entire_dashboard_search_session/`,
  deleteAllSessioninDash: `${dash_lens}/text_to_dashboard/Delete_entire_dash_vista_session/`,
  deleteSessionIdinDash: `${dash_lens}/text_to_dashboard/clear_dash_vista_sessions/`,
  deleteWorkBookKpi: `${dev}/login/delete_workbook_kpi/`,
  deleteSingleFile: `https://geninsights.agilisiumtech.com:448/document_search/delete_single_file/`,
  deleteCollectionCt: `${content_tagging}/content_tagging/delete_collection_ct/`,

  dataDetecSessionId: `https://geninsights.agilisiumtech.com:449/session/clear_sessions/`,
  dataDetecSessionAll: `https://geninsights.agilisiumtech.com:449/session/delete_entire_session/`,

  comDetecSessionId: `${com_data_detect}/session/clear_sessions/`,
  comDetecSessionAll: `${com_data_detect}/session/delete_entire_session/`,

  ZoomDetecSessionId: `https://geninsights.agilisiumtech.com:450/document_session/clear_doc_search_sessions`,
  ZoomDetecSessionAll: `https://geninsights.agilisiumtech.com:450/document_session/Delete_entire_doc_search_session`,

  deleteOnePersona: `https://geninsights.agilisiumtech.com:452/data_lens/delete_persona/`,

  omnilensDeleteEntireDocSearchSession: `${omni_chatbot}/sessions_endpoint/Delete_entire_doc_search_session/`,
  omnilensClearDocSearchSession: `${omni_chatbot}/sessions_endpoint/clear_doc_search_sessions/`,
};
