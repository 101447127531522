import React, { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useForm, useController } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { EdituserValidation } from "../../validation";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { updateUser } from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";
import { CommonBtn } from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";

const Edituser = ({
  editmodalShow,
  setEditModalShow,
  editData,
  personaOptions,
  setFetch,
  collection,
}) => {
  const { setCollectionfetch, setIsNavMenuVisibile } = useContext(MyContext);
  const [isAdmin, setIsAdmin] = useState([
    { value: "true", label: "true" },
    { value: "false", label: "false" },
  ]);
  const [adminValue, setAdminValue] = useState([]);

  const [solutionList, setSolutionList] = useState([
    {
      value: "Data Lens",
      label: "Data Lens",
    },
    {
      value: "Doc Sonar",
      label: "Doc Sonar",
    },
    {
      value: "Dash Lens",
      label: "Dash Lens",
    },
    {
      value: "Literature AI",
      label: "Literature AI",
    },
    {
      value: "Gene Inspector",
      label: "Gene Inspector",
    },
    {
      value: "Content Generation",
      label: "Content Generation",
    },
    {
      value: "HCP MLT",
      label: "HCP MLT",
    },
    {
      value: "MLR Review",
      label: "MLR Review",
    },
    {
      value: "Data Detective",
      label: "Data Detective",
    },
    {
      value: "C Data Detective",
      label: "C Data Detective",
    },
    {
      value: "Call Insight",
      label: "Call Insight",
    },
    {
      value: "OmniBot",
      label: "OmniBot",
    },
    {
      value: "Omni Lens",
      label: "Omni Lens",
    },
    {
      value: "Document Generation",
      label: "Document Generation",
    },
    {
      value: "Med Fusion Ai",
      label: "Med Fusion Ai",
    },
  ]);
  let a = editData?.persona_name?.split(",");
  let b = editData?.collection_name?.split(",");
  let solution_name = editData?.solution_name?.split(",");

  let defaultOptionsPersona = personaOptions?.filter((item) =>
    a?.includes(item.value)
  );
  let defaultOptionsCollection = collection?.filter((item) =>
    b?.includes(item.value)
  );

  let defaultOptionSolution = solutionList?.filter((item) =>
    solution_name?.includes(item.value)
  );

  const customStyles = {
    // You can adjust the height to your preference
    control: (provided) => ({
      ...provided,
      height: "30px", // Set the desired height
      overflow: "scroll",
    }),
  };
  const EditUser = useForm({
    resolver: zodResolver(EdituserValidation),
    mode: "all",
  });
  const collectionList = useController({
    name: "Collection",
    control: EditUser.control,
  });
  const handleCollection = (option) => {
    collectionList.field.onChange(option);
  };

  const solutionListData = useController({
    name: "Solution",
    control: EditUser.control,
  });
  const handleSolution = (option) => {
    solutionListData.field.onChange(option);
  };

  const IsAdmin = useController({
    name: "isAdmin",
    control: EditUser.control,
    defaultValue: editData && editData.is_admin === true ? "true" : "false",
  });
  const handleAdmin = (option) => {
    setAdminValue(option);
    IsAdmin.field.onChange(option.value);
  };
  const Persona = useController({
    name: "Persona",
    control: EditUser.control,
  });
  const handlePersona = (option) => {
    Persona.field.onChange(option);
  };

  const userData = async (formValues) => {
    EditUser.reset();

    setEditModalShow(false);
    formValues.Persona = formValues.Persona.map((e) => e.value);
    formValues.Collection = formValues.Collection.map((e) => e.value);
    formValues.Solution = formValues.Solution.map((e) => e.value);
    formValues.isAdmin = adminValue.value;
    let updateRes = await updateUser(formValues, setFetch, editData.email);

    setCollectionfetch((e) => !e);
    if (updateRes?.status === 200) {
      setIsNavMenuVisibile((e) => !e);
    }
  };

  useEffect(() => {
    EditUser.setValue("Persona", editData ? defaultOptionsPersona : "");
    EditUser.setValue("Firstname", editData ? editData.firstname : "");
    EditUser.setValue("Lastname", editData ? editData.lastname : "");
    EditUser.setValue("Email", editData ? editData.email : "");
    //EditUser.setValue("Password", editData ? editData.password : "");
    EditUser.setValue("Collection", editData ? defaultOptionsCollection : "");
    EditUser.setValue("Solution", editData ? defaultOptionSolution : "");

    EditUser.setValue(
      "isAdmin",
      editData && editData.isadmin === true ? "true" : "false"
    );
    setAdminValue(
      editData && editData.is_admin === true
        ? isAdmin.filter((e) => e.value === "true")[0]
        : isAdmin.filter((e) => e.value === "false")[0]
    );
  }, [editData]);
  return (
    <div>
      <Modal
        show={editmodalShow}
        // onHide={() => setEditModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        keyboard={false}
        centered
      >
        {" "}
        <form onSubmit={EditUser.handleSubmit(userData)}>
          <Modal.Header closeButton={true}>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit User {editData && editData.firstname}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="d-flex align-items-center gap-4"
              style={{ gap: "3%" }}
            >
              <div className="w-100 flex-item">
                <div className="mb-1">
                  Persona
                  <span className="" style={{ color: "red" }}>
                    *
                  </span>{" "}
                  :
                </div>
                <Select
                  isMulti
                  className="w-100"
                  placeholder="Select Persona"
                  // value={allValues.Region}
                  onChange={handlePersona}
                  options={personaOptions}
                  defaultValue={editData && defaultOptionsPersona}
                  // noOptionsMessage={() => noOptionsMessage}
                ></Select>
                <div style={{ color: "red" }}>
                  {EditUser.formState.errors.Persona?.message}
                </div>
              </div>
              <div className="w-100 flex-item">
                <div className="mb-1">
                  First Name
                  <span className="" style={{ color: "red" }}>
                    *
                  </span>{" "}
                  :
                </div>
                <input
                  type="text"
                  className="w-100"
                  {...EditUser.register("Firstname")}
                  style={{ height: "38px", padding: "10px" }}
                  placeholder="First Name"
                  defaultValue={editData && editData.firstname}
                />
                <div style={{ color: "red" }}>
                  {EditUser.formState.errors.Firstname?.message &&
                    EditUser.formState.errors.Firstname?.message}
                </div>
              </div>
            </div>
            <div
              className="d-flex align-items-center mt-2"
              style={{ gap: "3%" }}
            >
              <div className="w-100 flex-item">
                <div className="mt-2 mb-1">
                  Last Name
                  <span className="" style={{ color: "red" }}>
                    *
                  </span>{" "}
                  :
                </div>
                <input
                  type="text"
                  className="w-100"
                  {...EditUser.register("Lastname")}
                  style={{ height: "38px", padding: "10px" }}
                  placeholder="Last Name"
                  defaultValue={editData && editData.lastname}
                />
                <div style={{ color: "red" }}>
                  {EditUser.errors?.Lastname?.message &&
                    EditUser.errors.Lastname?.message}
                </div>
              </div>
              <div className="w-100 flex-item">
                <div className="mt-2 mb-1">
                  Email
                  <span className="" style={{ color: "red" }}>
                    *
                  </span>{" "}
                  :
                </div>
                <input
                  type="email"
                  className="w-100"
                  {...EditUser.register("Email")}
                  style={{ height: "38px", padding: "10px" }}
                  placeholder="Email"
                  defaultValue={editData && editData.email}
                />
                <div style={{ color: "red" }}>
                  {EditUser.formState.errors.Email?.message &&
                    EditUser.formState.errors.Email?.message}
                </div>
              </div>
            </div>
            <div
              className="d-flex align-items-center mt-2"
              style={{ gap: "3%" }}
            >
              {/* <div className="w-100 flex-item">
                <div className="mt-2 mb-1">
                  Password
                  <span className="" style={{ color: "red" }}>
                    *
                  </span>{" "}
                  :
                </div>
                <input
                  type="text"
                  className="w-100"
                  {...EditUser.register("Password")}
                  style={{ height: "38px", padding: "10px" }}
                  placeholder="Password"
                  defaultValue={editData && editData.password}
                />
                <div style={{ color: "red" }}>
                  {EditUser.formState.errors.Password?.message &&
                    EditUser.formState.errors.Password?.message}
                </div>
              </div> */}

              <div className="w-100 flex-item">
                <div className="mt-2 mb-1">
                  Collection
                  <span className="" style={{ color: "red" }}>
                    *
                  </span>{" "}
                  :
                </div>
                <Select
                  isMulti
                  //styles={customStyles}
                  // className="mt-3"
                  placeholder="Select Collection"
                  // value={allValues.Region}
                  onChange={handleCollection}
                  options={collection}
                  defaultValue={editData && defaultOptionsCollection}
                  // noOptionsMessage={() => noOptionsMessage}
                ></Select>
                <div style={{ color: "red" }}>
                  {EditUser.formState.errors.Collection?.message}
                </div>
              </div>

              <div className="w-100 flex-item">
                <div className="mt-2 mb-1">
                  Soultion Name
                  <span className="" style={{ color: "red" }}>
                    *
                  </span>{" "}
                  :
                </div>
                <Select
                  className="w-100"
                  placeholder="Select Solution"
                  // value={adminValue}
                  onChange={handleSolution}
                  options={solutionList}
                  defaultValue={editData && defaultOptionSolution}
                  isMulti
                ></Select>
                <div style={{ color: "red" }}>
                  {EditUser.formState.errors.Solution?.message &&
                    EditUser.formState.errors.Solution?.message}
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-center mt-2"
              style={{ gap: "3%" }}
            >
              <div className="col-6 p-0 pr-2">
                <div className="w-100 flex-item">
                  <div className="mt-2 mb-1">
                    Admin
                    <span className="" style={{ color: "red" }}>
                      *
                    </span>{" "}
                    :
                  </div>
                  <Select
                    className="w-100"
                    placeholder="Select Admin"
                    value={adminValue}
                    onChange={handleAdmin}
                    options={isAdmin}
                    defaultValue={
                      editData && editData.is_admin === true
                        ? isAdmin.filter((e) => e.value === "true")
                        : isAdmin.filter((e) => e.value === "false")
                    }
                    //   defaultValue={
                    // noOptionsMessage={() => noOptionsMessage}
                  ></Select>
                  <div style={{ color: "red" }}>
                    {EditUser.formState.errors.isAdmin?.message &&
                      EditUser.formState.errors.isAdmin?.message}
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <CommonBtn
              title={commonStr.cancel}
              onClick={() => setEditModalShow(false)}
              className="mr-2"
            />
            <CommonBtn type="submit" title={commonStr.update} />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default Edituser;
