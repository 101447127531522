import axiosApi from "../interceptors/axiosinterceptor";

import { deleteUrl } from "./Endpoint";
import { toast } from "react-toastify";

export const delete_file_s3_pub = async (body) => {
  try {
    let response = await axiosApi.delete(`${deleteUrl.delete_file_s3_pub}`, {
      data: body,
    });
    toast.success(`User "${body.filename}" Deleted Successfully!!!`);

    return response;
  } catch (err) {
    console.log("Error in deleting Persona", err);
  }
};

export const delete_disease_tagged_db = async (payload) => {
  try {
    let response = await axiosApi.delete(
      `${deleteUrl.delete_disease_tagged_db}${payload.filename}/${payload.first_name}`
    );
    return response;
  } catch (err) {
    console.log("Error in deleting Persona", err);
  }
};

export const deleteTableauSessionId = async (
  email,
  deleteSession,
  dashboardname
) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deleteTableauSession}${email}/${deleteSession}/${dashboardname}/`
    );
    let a = res.data;
    toast.error(`${res.data}`);
    return a;
  } catch (err) {
    console.log(err);
  }
};

export const deleteTableauSessionAll = async (email, dashboardname) => {
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.deleteTableauSessionAll}${email}/${dashboardname}/`
    );
    let a = res.data;
    console.log(res);
    toast.error("Sessions deleted successfully!!!");
    return a;
  } catch (Err) {
    console.log("Error in deleteDashboardAll", Err);
  }
};

export const deleteWorkBookApi = async (ele, setFetch) => {
  let a = toast.warning("WorkBook Deleting...", { autoClose: false });
  try {
    let response = await axiosApi.delete(
      `${deleteUrl.deleteWorkBookKpi}${ele.WorkBook}/`
    );
    toast.dismiss(a);
    toast.success(`${ele.WorkBook} "Deleted Successfully!!!"`);
    setFetch((current) => !current);
  } catch (err) {
    toast.error(`${err.response.data.detail}  `, {
      closeOnClick: true,
    });
    toast.dismiss(a);
  }
};

export const deleteSingleFile = async (body) => {
  try {
    let response = await axiosApi.delete(`${deleteUrl.deleteSingleFile}`, {
      data: body,
    });

    if (response) {
      return response;
    }
  } catch (err) {
    toast.error(`${err.response.data.detail}  `, {
      closeOnClick: true,
    });
  }
};

export const deleteCollectionCt = async (body) => {
  try {
    let response = await axiosApi.delete(`${deleteUrl.deleteCollectionCt}`, {
      data: body,
    });
    if (response) {
      return response;
    }
  } catch (err) {
    toast.error(`${err.response.data.detail}  `, {
      closeOnClick: true,
    });
  }
};

export const deleteDataDetectiveAll = async (body) => {
  let toastMsgId = toast.warning("Deleting Session...", { autoClose: false });
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.dataDetecSessionAll}${body.email}/${body.persona_name}`
    );
    toast.dismiss(toastMsgId);
    toast.success("Session Deleted Successfully");
  } catch (err) {
    console.log("Error in deleteDocumentAll", err);
  }
};

export const deleteDataDeleteSession = async (email, persona, sessionid) => {
  let toastMsgId = toast.warning(`${sessionid} Deleting`, {
    autoClose: false,
  });

  try {
    let res = await axiosApi.delete(
      `${deleteUrl.dataDetecSessionId}${email}/${persona}/${sessionid}/`
    );
    toast.dismiss(toastMsgId);
    toast.success(`${sessionid} Deleted Successfully`);
  } catch (err) {
    toast.dismiss(toastMsgId);
    console.log("Error in deleting session", err);
  }
};

export const comDetectiveDeleteAll = async (body) => {
  let toastMsgId = toast.warning("Deleting Session...", { autoClose: false });
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.comDetecSessionAll}${body.email}/${body.persona_name}/`
    );
    toast.dismiss(toastMsgId);
    toast.success("Session Deleted Successfully");
  } catch (err) {
    console.log("Error in deleteDocumentAll", err);
  }
};

export const comDetectDeleteSession = async (email, persona, sessionid) => {
  let toastMsgId = toast.warning(`${sessionid} Deleting`, {
    autoClose: false,
  });

  try {
    let res = await axiosApi.delete(
      `${deleteUrl.comDetecSessionId}${email}/${persona}/${sessionid}/`
    );
    toast.dismiss(toastMsgId);
    toast.success(`${sessionid} Deleted Successfully`);
  } catch (err) {
    toast.dismiss(toastMsgId);
    console.log("Error in deleting session", err);
  }
};

export const deleteZoomDeleteSession = async (email, colection, sessionid) => {
  let toastMsgId = toast.warning(`${sessionid} Deleting`, {
    autoClose: false,
  });

  try {
    let res = await axiosApi.delete(
      `${deleteUrl.ZoomDetecSessionId}/${email}/${sessionid}/${colection}`
    );
    toast.dismiss(toastMsgId);
    toast.success(`${sessionid} Deleted Successfully`);
  } catch (err) {
    toast.dismiss(toastMsgId);
    console.log("Error in deleting session", err);
  }
};

export const deleteZoomSeesionAll = async (body) => {
  let toastMsgId = toast.warning("Deleting Session...", { autoClose: false });
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.ZoomDetecSessionAll}/${body.email}/${body.collection_name}`
    );
    toast.dismiss(toastMsgId);
    toast.success("Session Deleted Successfully");
  } catch (err) {
    console.log("Error in deleteDocumentAll", err);
  }
};

export const omnilensDeleteEntireDocSearchSessionData = async (body) => {
  let toastMsgId = toast.warning("Deleting Session...", { autoClose: false });
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.omnilensDeleteEntireDocSearchSession}${body.email}`
    );
    toast.dismiss(toastMsgId);
    toast.success("Session Deleted Successfully");
  } catch (err) {
    console.log("Error in deleteDocumentAll", err);
  }
};

export const omnilensClearDocSearchSessionData = async (body) => {
  let toastMsgId = toast.warning("Deleting Session...", { autoClose: false });
  try {
    let res = await axiosApi.delete(
      `${deleteUrl.omnilensClearDocSearchSession}${body.email}/${body.deleteSession}`
    );
    toast.dismiss(toastMsgId);
    toast.success("Session Deleted Successfully");
  } catch (err) {
    console.log("Error in deleteDocumentAll", err);
  }
};