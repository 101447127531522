import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import NavbarComponent from "../common/navbar/Navbar";
import SidebarComponent from "../common/sidebar/Sidebar";

const Layout = ({ children }) => {
  const { instance } = useMsal();

  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    const sessionTimeout = 120 * 60 * 1000; // 2 hours, if user not access screen the session expired

    const sessionTimer = setTimeout(() => {
      setIsSessionExpired(true);
      instance.logout();
      sessionStorage.clear();
    }, sessionTimeout);

    const eventListener = () => {
      clearTimeout(sessionTimer);
      setTimeout(() => {
        setIsSessionExpired(true);
        instance.logout();
        sessionStorage.clear();
      }, sessionTimeout);
    };

    window.addEventListener("mousemove", eventListener);
    window.addEventListener("keydown", eventListener);

    return () => {
      clearTimeout(sessionTimer);
      window.removeEventListener("mousemove", eventListener);
      window.removeEventListener("keydown", eventListener);
    };
  }, []); // Add navigate as a dependency to useEffect

  return (
    <div className="App">
      <SidebarComponent />
      <div className="w-100">
        <NavbarComponent />
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Layout;
